.userCard{
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
      
    ul{
        list-style: none;
        li{
            font-size: 24px;
            &.bold {
                font-size: 16px;
            }
        }
        a{
            border-radius: 20px;
            display: inline-block;
            margin-top: 10px;
            padding: 10px 20px;
        }
    }
 
}

.user-details{
    
    label{
        font-size: 15px;
        margin-bottom: 0.3rem;
    }
    
    h4,
    h5,
    .h5{
        font-size: 18px;
        font-weight: normal;
    }
    
}

.edit-picture{
    position: absolute;
    top: 20px;
    right: 40px;
}

.verified{
    background-image: url('#{$imgDir}/icons/verification-tick.svg');
    width: 13px;
    height: 14px;
    background-size: contain;
    display: inline-block;
    top: 0;
    right: 0;
}