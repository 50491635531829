.loading {

    position: relative;
    min-height: 150px;

    * {
/*        visibility: hidden;
        opacity: 0;*/
    }

    &:before {
        content: '\e838';
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;

        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        /* opacity: .8; */

        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;

        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;

        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: .2em;

        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */

        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
        @extend .animate-spin;
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        margin-left: -32px;
        margin-top: -32px;
        font-size: 64px;
        text-indent: 0;
    }

    &.loading-min-height-350 {
        min-height: 350px;
    }

}