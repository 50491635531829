/*
    Top
*/


.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-70 {
    margin-top: 70px;
}

.margin-top-m-25 {
    margin-top: -25px;
}

/*
    Right
*/

.margin-right-0 {
    margin-right: 0;
}

.margin-right-auto {
    margin-right: auto;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-25 {
    margin-right: 25px;
}

/*
    Bottom
*/

.margin-bottom-10, .margin-bottom-10-px {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

/*
    Left
*/

.margin-left-auto {
    margin-left: auto;
}

.margin-left-0 {
    margin-left: 0;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-left-100 {
    margin-left: 100px;
}

.margin-left-25 {
    margin-left: 25px;
}

.left-40p {
    left: 40%;
}

/*
    Horizontal
*/

.margin-horiz-auto {
    @extend .margin-left-auto;
    @extend .margin-right-auto;
}

.margin-horiz-0 {
    @extend .margin-left-0;
    @extend .margin-right-0;
}

@include media-breakpoint-up (md) {
    .margin-left-10-pc-md-up {
        margin-left: 10%; 
    }

    .margin-left-15-md-up {
        margin-left: 15px;
    }

    .margin-top-70-md-up {
        margin-top: 70px;
    }

    .margin-left-100-md-up {
        margin-left: 100px;
    }
}

/*
    Vertical
*/

.margin-vert-50 {
    @extend .margin-top-50;
    @extend .margin-bottom-50;
}
