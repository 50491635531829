.custom-text-input{
    @extend .form-control;
    border: none;
    box-shadow: inset 0 0 3px #696868;
    border-radius: 10px;
    &::placeholder{
        color: $black;
    }
}
.btn-round-65 {
	width: 65%;
	border-radius: 15px;
}
.custom-select {
  
	position: relative;
	display: flex;
	width: 100%;
	height: 3em;
	line-height: 3;
	overflow: hidden;
	border-radius: 15px;
	font-weight: 500;
	padding-left: 15px;
	&:after{
		content: "";
		background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNC42OCAxMy41NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQuNjggMTMuNTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDA1NUI5O3N0cm9rZS13aWR0aDo0O3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9Cjwvc3R5bGU+Cjxwb2x5bGluZSBjbGFzcz0ic3QwIiBwb2ludHM9IjIyLjY4LDIgMTIuMzQsMTEuNTQgMiwyICIvPgo8L3N2Zz4K');
		background-size: 20px auto;
		background-repeat: no-repeat;
		background-position: 95% center;
		width: 20px;
		height: 50px;
		position: absolute;
		right: 15px;
		transition: 0.5s;
	}

	&.full-width{
		width: 100%;
	}

	&.inverted-shadow{
		box-shadow: inset 0 0 4px #0000008c;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		appearance: none;
		font-weight: 700;
		outline: 0;
		box-shadow: none;
		border: 0 !important;
		background: white;
		background-image: none;
		padding-left: 15px;
	}

	select::-ms-expand {
		display: none;
	}

	select {
		flex: 1;
		padding: 0 1.5rem;
		color: #000;
		cursor: pointer;
	}

	&.dropdown-open{
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		&:after{
			transform: rotate(180deg);
		}
	}

}

.custom-select-dropdown{
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	background-color: $white;
	padding: 0 0 80px 30px;
	position: absolute;
	width: 92.5%;
	z-index: 2;
	margin-top: -3px;
	box-shadow: inset 0 0 3px grey;

	.custom-select-action{
		position: absolute;
		width: 94%;
		left: 2px;
		bottom: 4px;
		background-color: $white;
		padding: 20px 30px 24px 30px;
		a{
			width: 100%;
			border-radius: 10px;
		}
	}
	
	
	ul{
		padding: 20px 0 0 0;
		overflow: auto;
		height: 195px;
		margin-bottom: 4px;

		&#rolesOptions,
		&#permissionsDiv,
		&#permissionsDivOfUser,
		&#permissionsDivForRegional{
			padding: 20px 0 15px 0;
		}
		
		li{
			margin-bottom: 0.6rem;
		}

	}

	input:checked,
	input:not(:checked) {
		position: absolute;
		left: -9999px;
	}

	input:checked + label,
	input:not(:checked) + label{
		position: relative;
		padding-left: 50px;
		cursor: pointer;
		line-height: 16px;
		display: inline-block;
		color: #0d0d0d;
		font-weight: 500;
	}

	input:checked + label:before,
	input:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 16px;
		height: 16px;
		border: none;
		box-shadow: inset 0 0 3px #5d5959;
		border-radius: 100%;
		background: #fff;
	}

	input:checked + label:after,
	input:not(:checked) + label:after {
		content: '';
		width: 10px;
		height: 10px;
		background: $blue;
		position: absolute;
		top: 3px;
		left: 3px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}

	input:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	input:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

}