/*
    Max widths
*/

.max-width-25-pc {
    max-width: 25%;
}

.max-width-75-pc {
    max-width: 75%;
}

.max-width-85-pc {
    max-width: 85%;
}

.max-width-95-pc {
    max-width: 95%;
}

@include media-breakpoint-up(md) {
    .max-width-85-pc {
        max-width: 85%;
    }
}

@include media-breakpoint-down(sm) {
    .max-width-100-pc-sm {
        max-width: 100%;
    }
}
