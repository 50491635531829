.download-tile{
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(lg){

        img{
            height: 60px;
        }
    }

    
    img{
        max-width: auto;
    }

}

.download-tile-text{
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    @include media-breakpoint-down(lg){
        font-size: 14px;
    }
}
.download-page{
    &.download-tier-2{
        .row{
            justify-content: space-between;
        }
        .col-md-6{
            @include media-breakpoint-up(md){
                flex: 0 0 48.5%;
                max-width: 48.5%;

            }
        }
    }
    .download-item{
        width: 268px;
        height: 268px;
        margin-bottom: 30px;
        margin-right: 30px;
        
        @include media-breakpoint-up(xl){
            &.padding-right-15{padding-right: 15px;}
            &.end-col-no-margin{
                margin-right: 0;
            }
        }

        @include max-width-1399{
            width: 230px;
            height: 230px;
        }

        @include media-breakpoint-down(lg){
            width: 200px;
            height: 200px;
        }

        .inner{
            @extend .box-shadow;
            @extend .border-radius-15;
            @extend .margin-bottom-30;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: 0.5s;
            
            &:hover{
                box-shadow: 0 0 20px #0000004d;
                .download-tile{
                    transition: all 0.2s ease-in-out;
                    transform: scale(1.1);
                }
            }

            .download-tile{
                transition: all 0.2s ease-in-out;
            }

            a{
                display: flex;
                justify-content: center;
                height: 100%;
                width: 100%;
                @extend .py-20;
                cursor: pointer;
            }
        }
        img{
            margin-top: -20px;
        }
    }
}

.tier-two-item{
    @extend .box-shadow;  
    @extend .padding-left-30; 
    @extend .border-radius-10; 
    @extend .py-20; 
    @extend .margin-bottom-30;
    min-height: 230px;
    position: relative;
    
    h4{
        font-size: 22px;
        margin: 20px 0;

    }

    p{
        font-size: 15px;
        line-height: 21px;
    }

    @include media-breakpoint-down(md){
        padding-bottom: 40px;
    }

    .download-link{
        position: absolute;
        bottom: 25px;
        right: 20px;
        @extend .text-sm-center; 
        @extend .text-lg-right; 
        @extend .margin-right-20;
    }
}

.download-tier-2{
    .product-item{
        &:hover h2{
            color: $black;
        }
    }
}