.background-image-carousel{
	
	margin-bottom: 1.8rem;
	@extend .border-radius-15;
	background-color: $black;

	.carousel-indicators{
		li{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			margin-right: 1rem;
			&:last-of-type{margin-right: 0;}
		}
	}

	.carousel-inner{
		@extend .border-radius-15;
		@extend .box-shadow;
	}

	.carousel-item{
		background-size: cover;
		background-position: center center;
		min-height: 250px;
		padding: 2rem;

		@include media-breakpoint-up(lg){
			padding: 3rem;
		}

		@include media-breakpoint-down(sm){
			padding: 1.5rem;
		}
		
		h2,p{
			color: $white;
			font-weight: 700;
		}

		h2{
			font-size: 1.5rem;
			line-height: 1;
			@include media-breakpoint-up(lg){
				font-size: 2.4rem;
				margin-bottom: 1.5rem;
			}
			@include media-breakpoint-down(sm){
				font-size: 1rem;
				line-height: 1rem;
			}
		}

		p{
			font-weight: 500;
			font-size: 1.15rem;
			line-height: 1.3rem;
			@include media-breakpoint-up(lg){
				margin-bottom: 2rem;
			}
			@include media-breakpoint-down(sm){
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
}

.carousel-indicators{
	li{
		width: 10px;
		height: 10px;
		border-radius: 50%;
		margin-right: 1rem;
		&:last-of-type{margin-right: 0;}
	}
}