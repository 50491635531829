.min-height-100vh {min-height: 100vh;}
.min-height-50vh{min-height: 50vh;}
.min-height-150{min-height: 150px;}
.height-1-px{height: 1px;}

@include media-breakpoint-up(lg){
    /* Min heights */
    
    .min-height-300-lg-up {
        min-height: 300px;
    }

   /* Heights */
   .height-85-pc-lg-up {
       height: 85%;
   } 
}

.height-45 {
    height: 45px;
}

.h-200 {
	height: 200px;	
}

.h-300 {
	height: 300px;	
}