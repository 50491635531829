
// Background Sizes

	.bg-contain{
		background-size: contain;
		background-repeat: no-repeat;
	}

	.bg-cover{
		background-size: cover;
		background-repeat: no-repeat;
	}

// Background Positions

	.bg-center{
		background-position: center center;
	}
// Background Colors 
	.bg-red{
		background-color: red;
	}

	.bg-blue{
		background-color: $blue;
	}
	.bg-transparent {
		background: transparent;
		background-color: transparent;

	}
	
	.dark-overlay {
    	background-color: rgba(0,0,0,0.5);
	}