.nav-tabs{
  border-bottom: 1px solid transparent;
  justify-content: space-between;
}

.nav-tabs {

  .single-tab{

    &.nav-item {
      width: 100%;
      position: relative;
      &:after{
        content: '';
        display: block;
        background: white;
        width: 100%;
        height: 30px;
        z-index: 1;
        position: absolute;
        bottom: -42px;
      }
      .nav-link{
        box-shadow: 0 -4px 24px 0 rgba(0,0,0,.15);
        cursor: default;
        color: $black;
        background-color: $white;
        margin: -4% 0 -14px 0;
        border-bottom: 14px solid $white;
        padding: 3% 0 1.5%;
        &.active{
          padding: 3% 0 1.5%;
        }
        &#marketing-tab{
          margin-left: 0!important;
          margin-right: 0!important;
        }
        &#training-tab{
          margin-right: 0!important;
          margin-left: 0!important;
        }
      }
    }
  }

  .nav-item {
    margin-bottom: -1px;
    width: 50%;
    text-align: center;
    padding: 0;
    font-size: 25px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    @include media-breakpoint-down(sm){
      font-size: 14px;
    }

    &:nth-child(2){
      .nav-link.active{
        box-shadow: 5px -8px 20px -13px rgba(0, 0, 0, 0.28);
      }
    }

    .nav-link {
      border: 0 solid transparent;
      font-weight: 900;
      color: $white;
      background-color: $old-black;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      padding: 2.5% 0;
      box-shadow: -5px -9px 20px -13px rgba(0,0,0,0.28);
      
      &.active{
        background-color: $white;
        color: $old-black;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        padding: 6% 0 3% 0;
        margin-top: -4%;
        position: relative;
        z-index: 1;
        margin-bottom: -14px;
        border-bottom: 14px solid $white;
        
        @include media-breakpoint-down(sm){
          padding: 8% 0px 0% 0;
          margin-top: -5%;
        }

        &#training-tab{
          margin-right: -2%;
          margin-left: 0;
        }

        &#marketing-tab{
          margin-left: -2%;
          margin-right: 0;
        }

      }

      &#training-tab{
        margin-left: 4.5%;
        @include media-breakpoint-down(sm){
          margin-left: 7.6%;
        }
      }

      &#marketing-tab{
        margin-right: 4.5%;
        @include media-breakpoint-down(sm){
          margin-right: 7.6%;
        }
      }

 } 
}
}


.tab-content {
  border-radius: 15px  15px ;
  margin-top: 9%;

}

.dashboard{
  
  .custom-progress-width{
    @include media-breakpoint-up(lg){
      flex: 0 0 41.333333%;
      max-width: 40.333333%;
    }
  }

  .custom-taskboard-width{
    @include media-breakpoint-up(lg){
      flex: 0 0 59.666667%;
      max-width: 59.6666666667%;
    }
  }

}

.dashboard-panel-text {
    p {
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: 0.5rem;
        @include media-breakpoint-down(sm){
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
        
    }
}

.background-image-carousel {
  @include media-breakpoint-down(md){
    padding: 0 0;
  }
}



.taskboard {
  margin-top: 5%; 
  margin-top: 5%; 
  padding: 40px 60px 30px 60px;
  min-height: 335px;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  @include media-breakpoint-down(md){
    padding: 20px;
    h2{
      font-size: 1.3rem;
    }
  }
  
  h2{
    width: 100%;
  }

  .list{
    &.hex{width: 100%;}
  }

}


/* Progress Bar */

  
  .progress--circle {
    position: relative;
    display: inline-block;
    margin: 11% auto 0 auto;
    width: 320px;
    height: 320px;
    border-radius: 50%;
    background-color: $blue;
    
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 200px;
      height: 200px;
      border-radius: 100%;
      background-color: white;
      box-shadow: 0px 0px 15px 0 #1d1d1d26;
    }

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%; 
      background-color: $blue;
    }

    @include media-breakpoint-down(sm){
      
      width: 250px;
      height: 250px;
      margin: 0 auto;

      &:before{
        width: 150px;
        height: 150px;
      }

      .progress-number{
        font-size: 31px;
      }

    }

  }
  
  .progress-number {
    position: absolute;
    top: 43%;
    left: calc(50% - 100px);
    width: 200px;
    line-height: 42px;
    margin-top: -0.75rem;
    text-align: center;
    font-size: 42px;
    color: #0D0D0D;
    font-weight: 700;
    border-radius: 50%;

    @include media-breakpoint-down(sm){
      top: 43%;
    }
    
    strong{
        font-size: 16px;
        display: block;
        font-weight: 700;
        @include media-breakpoint-down(sm){
          line-height: 16px;
        }
    }

  }
    
#tasks-progress {
  transform: rotate(90deg);

  .circle {
    width: 320px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    border-radius: 50%;
    box-shadow: inset 0px 0px 15px 0 #1d1d1d26;
  
    canvas {
      vertical-align: top;
      width: 320px;
    }
  }
}

.inner-shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 7px 7px rgba(29,29,29,0.08);
    background: #fff;
    transform: translate(-50%, -50%);
    max-width: 207px;
    max-height: 207px;
}
  
  .questionnaire-info-block {
      cursor: pointer;
  }

  .custom-col-width{
    &.spacing{
      margin-bottom: 1%;
      @include media-breakpoint-up(lg){
        flex: 0 0 48.6666666667%;
        max-width: 48.6666666667%;
      }
    }
  }

  .courses-status{
    
    &.incomplete{
      .status{
        color: $red;
      }
    }

    &.complete{

      .product-item{
        h2, p, p a, .timer{
          color: $light-grey;
        }

        .timer:before{
          background-image: url('#{$imgDir}/icons/clock.svg');
        }

        .status{
          color: $green;
        }
      }

    }

  }