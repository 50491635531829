.document-categories-list{
    padding: 0;
    margin: 0;
    list-style: none;
    @include media-breakpoint-up(lg){
        margin: 0 0 4rem 0;
    }
    li{
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0.5rem 0.5rem 1.3rem;
        align-items: center;
        &:nth-child(odd){
            background-color: $lighter-grey;
        }
    }
}