#login-box{

	@-webkit-keyframes autofill {
	   to {
	       color: #fff;
	       background: transparent;
	       font-size: 17px;
	   }
	}

	input:-webkit-autofill {
	   -webkit-animation-name: autofill;
	   -webkit-animation-fill-mode: both;
	   font-size: 17px;
	}
	
	@extend .bg-blue-square-pattern-rounded;
	@extend .bg-cover;
	@extend .bg-center;
	@extend .pt-4;
	box-shadow: -1px 6px 20px #00000069;
	border-radius: 15px;
	width: 450px;
	font-family: 'HelveticaNeue';
	font-weight: 700;
	min-height: 385px;

	@include media-breakpoint-down(xs){
		width: 98%;
		margin: 0 2%;
	}

	h1,
	label,
	input[type="email"],
	input[type="password"],
	input[type="submit"]{
		font-weight: 700;
	}

	h1{
		font-size: 21px;
		@include media-breakpoint-down(sm){
			font-size: 16px;
		}
        @include media-breakpoint-down (xs) {
            font-size: 12px;
        }
	}

	.form-control{

	}

	label{
		font-size: 11px;
		color: $white;
		margin-bottom: 0;
	}

	input[type="email"],
	input[type="password"]{
		background-color: transparent;
		border: none;
		color: $white;
		font-size: 17px;
		border-bottom: 1px solid $white;
		border-radius: 0;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0;
		height: 33px;
		margin-top: -4px;
		&:focus,
		&:active{
			outline: 0!important;
		}
	}

	input[type="submit"]{
		background-color: transparent;
		border: 2px solid $white;
		border-radius: 25px;
		color: $white;
		padding: 0;
		width: 108px;
		height: 31px;
		font-size: 14px;
		display: inline-block;
		padding: 0 15px;
		margin-top: 10px;
		&.reset-login{width: 210px;}
		&.reset-button{width: 150px;}

		&:hover {
			background-color: $white;
			color: $blue;
		}
	}

	p{
		small{
			font-size: 8px;
			font-weight: 700;
		}
		&.reset-text{
			font-size: 12px;
			color: $white;
			text-align: center;
			margin: 26px 0 19px 0;
		}
	}
	
	.checkbox-inline {
	  width: 150px;
	  text-align: left;

		& input {
	  		position: absolute;
	  		z-index: -1;
	  		opacity: 0;
		}
	}

	.form-check-label {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-left: 20px;

		&:hover {
			cursor: pointer;

			input ~ {
				.control_indicator {
	  				background: $white;
				
				&:focus ~ {
					.control_indicator {
						background: $white;
					}
				}	
				}
			}
		}

		.control_indicator {
		 	position: absolute;
		 	left: 0;
		 	top: 4px;
		 	width: 10px;
		 	height: 10px;
		 	background: $white;
		 	border-radius: 50%;

		 	&:after {
		 		position: absolute;
		  		display: none;
		  		content: '';
		 	}
		}

	}

	/* Checked state */
	.form-check-label input:checked ~ .control_indicator {
	  background: $blue;
	  border: 2px solid $white;
	}

	/* Show check mark */
	.form-check-label input:checked ~ .control_indicator:after {
	  display: block;
	}

}
