 .courses {
     visibility: hidden;

    > div * {
         visibility: visible;
     }

     > * {
        opacity: 1;
        transition: opacity 0.25s ease-in-out;
     }
 
     &:hover {
        > * {
            opacity: 0.45;
            transition: opacity 0.25s ease-in-out;
        }
     }
 
     > *:hover {
         opacity: 1;

        .small-title {
            color: $blue;
        }
     }
 }

 .courses {
    a{
        text-decoration: none;
    }
    &:hover {
        
        .action-link {
            color: $light-grey;
                
            &:before  {
                background-image: url('#{$imgDir}/icons/grey-download-folder.svg');
            }

        }
        
        .more-description {
            color: $old-black;
            transition: color 0.25s ease-in-out;
        }

        .courses-status .product-item .timer {
            color: $light-grey;
            transition: color 0.25s ease-in-out;

            &:before {
                background-image: url('#{$imgDir}/icons/grey-clock.svg');
            }
    
        }
        .courses-status .product-item .status {
            color: $light-grey;
            transition: color 0.25s ease-in-out;    
        }
     
         > *:hover {

            .more-description {
                color: $blue;
                transition: color 0.25s ease-in-out;
            }
        
            .action-link {
                color: $blue;
                
                &:before  {
                    background-image: url('#{$imgDir}/icons/download-folder.svg');
                }

            }


            &.courses-status {
                
                .status {
                    &.complete {
                        color: $green;
                        transition: color 0.25s ease-in-out;
                    }
                
                    &.incomplete {

                        color: $red;
                        transition: color 0.25s ease-in-out;

                    }   
                }

                &.complete {
                    
                    .product-item {

                        .small-title {
                            color: $blue;
                        }
                        
                        .timer {
                            color: $light-grey;
                        }

                    }
                }

                &.incomplete {

                    .product-item {

                        .more-description {
                            color: $blue;
                            transition: color 0.25s ease-in-out;
                        }

                        .timer {
                            color: $blue;
                            transition: color 0.25s ease-in-out;
                            &:before {
                                background-image: url('#{$imgDir}/icons/clock.svg');
                            }
                        }
                    }
               }
            }
            
        }     
         
    }

}

.courses ~ .product-item:hover {
    border: 1px solid red;
}


.circular-power-connectors {
        .product-item {
            .grayscale-bg {
                background-size: 54%;
                background-position: 50% 31%;
            }

            &:hover {
                .grayscale-bg {
                    background-size: 60%;
                }
            }
        }
    }

    .circular-data-connectors {
        .product-item {
            .grayscale-bg {
                background-size: 56%;
                background-position: 50% 71%;
            }

            &:hover {
                .grayscale-bg {
                    background-size: 60%;
                }
            }
        }
    }

    .circular-automation-connectors {
        .product-item {
            .grayscale-bg {
                background-size: 48%;
                background-position: 50% 94%;
            }

            &:hover {
                .grayscale-bg {
                    background-size: 55%;
                }
            }
        }
    }

    .switches{
        .product-item{
            .grayscale-bg{
                background-size: 53%;
                background-position: 43% 69%;
            }
            &:hover {
                .grayscale-bg {
                    background-size: 60%;
                }
            }
        }
    }

    .battery-holders{
        .product-item{
            .grayscale-bg{
                background-size: 55%;
                background-position: 50% 72%;
            }
            &:hover {
                .grayscale-bg {
                    background-size: 60%;
                }
            }
        }
    }

    .iec-connectors{
        .product-item{
            .grayscale-bg{
                background-size: 60%;
                background-position: 50% 62%;
            }
            &:hover {
                .grayscale-bg {
                    background-size: 65%;
                }
            }
        }
    }

    .iec-power-distribution-units{
        .product-item{
            .grayscale-bg{
                background-size: 55%;
                background-position: 50% 72%;
            }
            &:hover {
                .grayscale-bg {
                    background-size: 60%;
                }
            }
        }
    }

    .power-entry-modules{
        .product-item{
            .grayscale-bg{
                background-size: 60%;
                background-position: 50% 58%;
            }
            &:hover {
                .grayscale-bg {
                    background-size: 65%;
                }
            }
        }
    }

    .emi-mains-filters{
        .product-item{
            .grayscale-bg{
                background-size: 80%;
                background-position: 50% 60%;
            }
            &:hover {
                .grayscale-bg {
                    background-size: 85%;
                }
            }
        }
    }

    .fuse-holders{
        .product-item{
            .grayscale-bg{
                background-size: 70%;
                background-position: 50% 65%;
            }
            &:hover {
                .grayscale-bg {
                    background-size: 75%;
                }
            }
        }
    }

    .enclosures{
        .product-item{
            .grayscale-bg{
                background-size: 50%;
                background-position: 50% 80%;
            }
            &:hover {
                .grayscale-bg {
                    background-size: 55%;
                }
            }
        }
    } 



.product-item{
    cursor: pointer;
    padding: 30px;
    box-shadow: 0 0 20px #00000038;
    border-radius: 15px;
    min-height: 230px;
    position: relative;
    color: $black;
    transition: 0.5s;
    
    h2{transition: 0.5s;}

    .grayscale-bg{
        min-height: 230px;
        background-position: center center;
        background-size: 60%;
        background-repeat: no-repeat;
        filter: grayscale(50%);
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: 0.5s;
    }
    
    &.product-name{
        font-size: 30px;
    }

    .dashboard-panel-text{
        @include media-breakpoint-down(sm){
            margin-bottom: 1rem;
        }
    }

    &:hover{
        box-shadow: 0 0 20px #0000004d;
        .grayscale-bg{background-size: 68%;}
        h2{color:  $blue;}
        &~{
            opacity: 0.5;
        }
    }
 
    &.download{
        min-height: 250px;
    }

    &.downloads, &.brand{
        min-height: 170px;
        width: 170px;
        margin: 0 auto;

        a {
            padding: 15px;
            width: 100%;
            height: 100%;

            .grow {
                transition: all .2s ease-in-out;
            }

            &:hover {
                text-decoration: none;

                .grow {
                    transition: all .2s ease-in-out;
                    transform: scale(1.1); 
                }
            }


        }
    }
   
    .img-wrapper{
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    h2{
        font-size: 22px;
        font-weight: 700;
        text-transform: capitalize;
        line-height: 1;
        padding: 2.1rem 0 0.5rem 0;
        @include media-breakpoint-down(sm){
            font-size: 16px;
            line-height: 18px;
        }
        
    }

    p{
        font-size: 15px;
        line-height: 18px;
        color: $old-black;
        @include media-breakpoint-down(sm){
            font-size: 12px;
            line-height: 14px;
        }
    }

    .more-description{
        font-weight: 700;
        color: $blue;
    }

    .action-link{
        font-weight: 700;
        color: $blue;
        font-size: 16px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
        transition: color 0.25s ease-in-out;
        position: absolute;
        bottom: 1.3rem;
        right: 2.5rem;

        @include media-breakpoint-down(sm){
            font-size: 14px;
            line-height: 18px;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            position: relative;
            justify-content: center;
        }

        &.stop-propagation{
            @include media-breakpoint-down(sm){
                left: 50%;
            }
        }

        &.custom{
            position: relative;
            justify-content: center;
            left: auto;
            right: auto;
            bottom: auto;
            &.download{
                justify-content: flex-end;
                font-size: 1rem;
                right: 2.5rem;
                
                @include media-breakpoint-down(lg){
                    transform: none;
                    width: 100%;
                    bottom: 1rem;
                }

                @include media-breakpoint-down(sm){
                    right: auto;
                    justify-content: center;
                }

            }
        }
        
        &:before{
            content: '';
            background-image: url('#{$imgDir}/icons/download-folder.svg');
            @extend .bg-contain;
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-right: 10px;
            background-size: contain;
            background-position: center left;
            margin-top: -5px;
            @include media-breakpoint-down(sm){

                width: 20px;
                height: 20px;
                left: -8px;
            
            }
        }

        &.download{
            font-size: 20px;
        }

        &.no-icon{
            display: flex;
            i{
                font-size: 28px;
            }
            &:before{
                display: none;
            }
        }

    }

    &.tiered-product-item{
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: 75%;
        
        h2{
            font-size: 18px;
            line-height: 21px;
            @include max-width-1290{
                font-size: 14px;
                line-height: 18px;
            }
            @include media-breakpoint-down(sm){
                font-size: 16px;
                line-height: 18px;
            }
        }

        p{
            font-size: 12px;
            line-height: 20px;
        }

        .product-text-wrapper{
            @include max-width-1290{
                margin-bottom: 1rem;
            }
        }

        .action-link{
            width: 100%;
            
            @include max-width-1290{
                font-size: 14px;
                padding-left: 0;
                bottom: 15px;
            }

            @include media-breakpoint-down(sm){
                left: 50%;
                transform: translateX(-50%);
                width: 70%;
            }

            &.custom{
                left: 0;
                right: 0;
                justify-content: center;    
            }
            
            &:before{
                left: 0;
            }
        }
        .product-name{
            font-size: 25px;
            line-height: 35px;
        }
    }

}

.transbox {
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(255,255,255,0.9); 
    border-radius: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    top: 0;
    left: 0;
  }

  .courses-status{

      .incomplete{
        .status{
            color: $red;
        }
      }

      &.complete{
            opacity: 0.9;

            .grayscale-bg {
                opacity: 0.4;
            }
        
            .product-item{

                box-shadow: 0 0 20px rgba(0,0,0, 0.1);

                h2, p{
                    color: $light-grey;
                }

                .timer:before{
                    background-image: url('#{$imgDir}/icons/grey-clock.svg');
                }
            }

            .status{
                color: $green;
            }

            &:hover {

                .grayscale-bg {
                opacity: 0.4;
                }
        
                .product-item{
                    box-shadow: 0 0 20px rgba(0,0,0,0.22);
                }
            }
    }

    .product-item{
    
      
        .timer{
            font-weight: 700;
            color: $blue;
            font-size: 18px;
            line-height: 30px;
            position: relative;
            padding-left: 30px;
            margin-bottom: 45px;
            &:before{
                content: '';
                background-image: url('#{$imgDir}/icons/clock.svg');
                @extend .bg-contain;
                width: 30px;
                height: 25px;
                display: inline-block;
                margin-right: 10px;
                position: absolute;
                left: -5px;
                top: 3px;
            }
            @include media-breakpoint-down(sm){
                font-size: 12px;
                line-height: 20px;
                padding-left: 22px;
                &:before{
                    width: 20px;
                    height: 15px;
                    left: 5px;
                }
            }
        }

        .status{
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            @include media-breakpoint-down(sm){
                font-size: 12px;
                line-height: 20px;
            }
        }

    }
    
 }
  
