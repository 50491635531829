.button{
	@extend .border-radius-15;
	display: inline-block;
	font-size: 13px;
	font-weight: 700;
	padding: 5px 20px;
	transition: 0.5s;
	&.transparent{
		&.white{
			color: $white;
			border: 2px solid $white;
			&.inverted-hover{
				&:hover{
					background-color: $white;
					color: $black;
					text-decoration: none;
				}
			}
		}
	}
}

.btn {
	&.delete-data-modal-button, &.restore-data-modal-button  {
		border-color:  darken($warning, 5%) ;
		position: relative;
		padding: 1rem 2.3rem 1rem 1rem;
		&.no-display{
			padding: 1rem;
		}

		.close-delete, .close-restore {			
			background-color: $warning;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
			font-size: 1rem;
			padding: 0 0.5rem;
			height: 100%;
		}
	}

	&.small-padding{
		padding: 0 0.5rem;
	}

}