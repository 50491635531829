* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('#{$fontDir}/HelveticaNeue-Bold.eot');
    src: url('#{$fontDir}/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$fontDir}/HelveticaNeue-Bold.woff') format('woff'),
        url('#{$fontDir}/HelveticaNeue-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('#{$fontDir}/HelveticaNeue-Medium.eot');
    src: url('#{$fontDir}/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$fontDir}/HelveticaNeue-Medium.woff') format('woff'),
        url('#{$fontDir}/HelveticaNeue-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('#{$fontDir}/HelveticaNeue.eot');
    src: url('#{$fontDir}/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
        url('#{$fontDir}/HelveticaNeue.woff') format('woff'),
        url('#{$fontDir}/HelveticaNeue.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fontello';
    src: url('#{$fontDir}/fontello.eot?70428830');
    src: url('#{$fontDir}/fontello.eot?70428830#iefix') format('embedded-opentype'),
         url('#{$fontDir}/fontello.woff2?70428830') format('woff2'),
         url('#{$fontDir}/fontello.woff?70428830') format('woff'),
         url('#{$fontDir}/fontello.ttf?70428830') format('truetype'),
         url('#{$fontDir}/fontello.svg?70428830#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }

.helvetica-neue-bold {
    font-family: 'HelveticaNeue', sans-serif;
    font-weight: 700;
}
