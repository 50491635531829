.pos-horiz-center{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.pos-bottom-17-pc{
    bottom: 17%;
}

/*
    Top
*/

.top-0 {
    top: 0;
}

/*
    Right
*/

.right-20 {
    right: 20px;
}
