#header{
	background-image: url('#{$imgDir}/backgrounds/blue-header.jpg');
	background-attachment: fixed;
	padding: 1% 0;
	min-height: 143px;
	height: 1px;
	display: flex;
	align-items: center;

	@include media-breakpoint-down(lg){
		min-height: 95px;
		height: auto;
	}

	@include media-breakpoint-down(sm){
		min-height: 75px;
	}

	.navbar{
		width: 100%;
		li{
			@include media-breakpoint-down(md){
				ul{
					text-align: center;
					list-style: none;
					padding: 30px 0;
					margin: 15px 0 20px 0;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-flow: column;
					background-color: rgba(0, 0, 0, 0.5);
					li{
						a{
							font-size: 1
						}
					}
				}
			}
		}
	}
	
	.seperator{
		color: $white;
		font-weight: bold;
		padding: 0 0.5rem;
		font-size: 1rem;
		display: none;
		
		@include min-width-1400{
			padding: 0 1.5rem;
			font-size: 21px;
		}

		@include media-breakpoint-up(xl){
			display: block;
		}

	}

	.navbar-toggler{
	    border: 2px solid #fff;
	    padding: 15px 15px;
	    -moz-border-radius: 0;
	    border-radius: 0;
	    width: 45px;
	    height: 45px;
	    &:focus{outline: 0;}
	    @include media-breakpoint-down(md){
			display: flex;
			align-items: center;
			justify-content: center;
			flex-flow: column;
	    }
	    
	    .toggler-bar{
	        border-bottom: 2px solid $white;
	        display: block;
	        width: 15px;
	        height: 1px;
	        margin: 2px 0px;
	        &:first-of-type{
	            margin-top: 0;
	        }
	        &:last-of-type{
	            margin-bottom: 0;
	        }
	    }
	    &:hover{
	        border-width: 2px;
	        background: none;
	        border-color: $white;
	    }
	}

	a{
		color: $white;
		font-weight: 700;
		font-size: 1rem;
		line-height: 1.5rem;
		&:hover{text-decoration: none;}
		
		@include min-width-1400{
			font-size: 21px;
		}

		img{
			@include media-breakpoint-down(lg){
				width: 100px;
			}
			@include media-breakpoint-down(sm){
				width: 80px;
			}
		}
	
	}

	a.navbarDropdown {
		line-height: 4rem;
	}

	.form-control{
		height: 30px;
		line-height: 15px;
		font-weight: 500;
	}

	input[type=text]{
		border: none;
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
		border-top-right-radius: 25px;
		border-bottom-right-radius: 25px;
	
		@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
		@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
		@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
		@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */  width: 360px;}
		@media (min-width:1280px) { /* big landscape tablets, laptops, and desktops */ width: 459px; }
		@media (min-width:1481px) { /* hi-res laptops and desktops */ width: 515px; }
		
		
	}

	#header-search{
		@include media-breakpoint-down(md){
			display: none;
		}		
	}

	.search{
		border: transparent solid;
		outline: none;
		border-radius: none;
		border-top-right-radius: 25px;
		border-bottom-right-radius: 25px;
		position: relative;
    	left: -46px;
		
	}

	

	.avatar{
		border-radius: 100%;
		height: 50px;
		width: 50px;
	}

	.dropdown-menu  {
		border-radius:  5px;
		padding: 25px 30px 25px 30px;
		position: absolute;
		right: -17px;
		@supports (-ms-ime-align: auto) {
			right: -24px;
		}
		left: auto!important;
		width: 210px;
		list-style: none;
		background-color: $black!important;
		z-index: 1500;
		text-align: left;
		-moz-animation: fadeIn .1s ease-in both;
		animation: fadeIn .1s ease-in both;
		@include media-breakpoint-down(sm){
			left: 79px!important;
		}
		.triangle {
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
			border-bottom: 24px solid $black;
			top: -20px;
			left: 80%;
			margin-left: -10px;
			position: absolute;

		}
	
   
	
		
		ul{
			list-style:none;
			padding: 0;
			li{
				&:first-of-type{
					margin-bottom: 15px;
				}
			
				margin-bottom: 15px;
				&:last-of-type{margin-bottom: 0;}

			}
			a{
				padding: 0;
				line-height: 1;
				font-size: 18px;
				&:hover{
					background-color: transparent;
					color: $white;
					cursor: pointer;
				}
			}

		}
		
		&:after, &:before {
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 24px solid $black;
			top: -15px;
			left: 70%;
			margin-left: -10px;
			position: absolute;
		  }
		  &:before {
			border-left: 14px solid transparent;
			border-right: 14px solid transparent;
			border-bottom: 33px solid $black;
			border-top-color: $black;
			top: -25px;
			margin-left: -14px;
		  }

		
		
	}

	span.arrow{
		width:0;
		height:0;
		border-top: 10px solid #fff;
		border-left: 6px solid transparent; 
		border-right: 6px solid transparent;
		position: relative;
		top: 1px;
		margin-left: 5px;

	}

}
