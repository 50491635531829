.px-100{
    padding-left: 100px;
    padding-right: 100px;

    @include media-breakpoint-down(md){
        padding-left: 0px;
        padding-right: 0px;

    }

    @include media-breakpoint-down(lg){
        padding-left: 50px;
        padding-right: 50px;
    }

    @include media-breakpoint-down(sm){
        padding-left: 25px;
        padding-right: 25px;
    }
}

.px-60{
    padding-left: 60px;
    padding-right: 60px;

    @include media-breakpoint-down(md){
        padding-left: 0px;
        padding-right: 0px;

    }

    @include media-breakpoint-down(lg){
        padding-left: 45px;
        padding-right: 45px;
    }

    @include media-breakpoint-down(sm){
        padding-left: 20px;
        padding-right: 20px;
    }
}

.px-80{
    padding-left: 80px;
    padding-right: 80px;

    @include media-breakpoint-down(md){
        padding-left: 0px;
        padding-right: 0px;

    }

    @include media-breakpoint-down(lg){
        padding-left: 50px;
        padding-right: 50px;
    }

    @include media-breakpoint-down(sm){
        padding-left: 25px;
        padding-right: 25px;
    }
}

.px-125{
    padding-left: 125px;
    padding-right: 125px;

    @include media-breakpoint-down(md){
        padding-left: 0px;
        padding-right: 0px;

    }

    @include media-breakpoint-down(lg){
        padding-left: 50px;
        padding-right: 50px;
    }

    @include media-breakpoint-down(sm){
        padding-left: 25px;
        padding-right: 25px;
    }
}



.pt-10{
    padding-top: 10px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-80 {
    padding-top: 80px;
}

.py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.py-80{
    padding-top: 80px;
    padding-bottom: 80px;
}

.pb-40{
    padding-bottom: 40px;
}

.pb-80{
    padding-bottom: 80px;
}

.padding-left-30 {
    padding-left: 30px;
}

.padding-left-80 {
    padding-left: 80px;
}

.padding-right-80 {
    padding-right: 80px;
}

.p-30{
    padding: 30px;
}

.p-40{
    padding: 40px;
}

.p-50{
    padding: 20px;
}

.p-80 {
    padding: 80px;
}

.px-5-rem{
    padding-left: 5rem;
    padding-right: 5rem;
}

.padding-40-horiz {
    padding-left: 40px;
    padding-right: 40px;
}

.py-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.py-36 {
    padding-left: 36px;
    padding-right: 36px;
}

@include media-breakpoint-up(xl) {
    .padding-80-horiz-xl-up {
        padding-left: 80px;
        padding-right: 80px;
    }
}
