.custom-dropdown{
    
    border-radius: 10px;
    box-shadow: inset 0 0 4px #0000008c;
    padding: 15px;
    flex-direction: column;
    display: flex;

    label{
        margin-bottom: 20px;
        input{margin-right: 20px;}
    }

}