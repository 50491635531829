@include media-breakpoint-up (lg) {
    .text-lg-right {
        text-align: right;
    }
} 

@include media-breakpoint-down(sm) {
    .text-center-sm {
        text-align: center;
    }
}
