@mixin max-width-1399{
    @media (max-width: 1399px){
        @content;
    }
}

@mixin max-width-1290{
    @media (max-width: 1290px){
        @content;
    }
}

@mixin min-width-1400{
	@media (min-width: 1400px){
		@content;
	}
}