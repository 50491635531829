.modal-content{
    
    .close {
        position: absolute;
        &:not([class*=" top-"]) {
            top: 15px;
        }
        &:not([class*=" right-"]) {
            right: 15px;
        }
    }

    p{
        font-weight: 500;
        line-height: 21px;
    }

	.modal-body{
        h4{
            text-transform: capitalize;
            word-wrap: break-word;
        }
		p{
            word-wrap: break-word;
			br{display: none;}
		}
	}

}

.modal-title {
    text-transform: capitalize;
}

.branded-modal{
    
    h2{
        @include media-breakpoint-up(lg){
            font-size: 2.13rem;
            line-height: 2.13rem;
        }
    }

    h2,h3{
        font-weight: 700;
    }

    label{
        font-weight: 700;
        margin-left: 10px;
    }
    
    input{
        &[type="text"],
        &[type="email"],
        &[type="password"]{
            box-shadow: inset 0 0 4px #0000008c;
            border-radius: 10px;
            border: none;
        }
    }

    .btn{
        font-size: 1.1rem;
        font-weight: 700;
        padding: 10px 30px;
        color: $white;
        border-radius: 10px;
    }

    .custom-select-dropdown{
        margin-top: -1px;
        padding: 10px 0 30px 30px;
        width: 92%;
        ul{
            height: auto;
            overflow-y: hidden;
            
            &.dropdown-scroll{
                overflow-y: scroll;
                height: 240px;
            }

            li{
                margin-bottom: 0.6rem;
            }
            
        }
        .custom-select-action{
            position: relative;
            padding: 0;
            a{
                width: 95%;
            }
        }
    }

    .custom-select{
        height: 2.3rem;
        line-height: 2.3rem;
        border-radius: 10px;
        box-shadow: inset 0 0 4px #0000008c;
        padding-left: 15px;
        font-weight: normal;
        &.dropdown-open{
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        select{
            line-height: 1;
        }
        &::after{
            line-height: 2rem;
            right: 15px;
            line-height: 1.5rem;
            top: -5px;
            padding: 0;
        }
    }

}

.modal-larger{
    @include media-breakpoint-up(lg){
        max-width: 900px;
    }
}

.modal{
    &.video-popup{
        .modal-content{
            .close{
                top: 5px;
                right: 10px;
            }
        }
    }
}

#download-staff-document-modal,
#delete-document-modal{
    left: 50%;
    transform: translateX(-50%);
    max-width: 520px;
    width: 100%;
    @include media-breakpoint-down(sm){
        h2{font-size: 1.5rem;}
        p,.btn{
            font-size: 0.85rem;
        }
    }

    p{
        margin-bottom: 0;
        font-weight: normal;
        margin-bottom: 0;
        line-height: 1.3;
    }

    .modal-content{
        padding: 1rem 1.5rem 1.5rem 1.5rem;
        @include media-breakpoint-up(lg){
            padding: 2rem 3rem 3rem 3rem;
            .btn-primary{width: 100%;}
        }
    }

    .modal-footer{
        @include media-breakpoint-up(lg){
            padding-left: 0;
            padding-right: 0;
            justify-content: space-between;
            margin-top: 1rem;
        }
    }

}