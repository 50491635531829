/*
    Min heights
*/

.min-height-15-vh {
    min-height: 15vh;
}

.min-height-500{
    min-height: 500px;
}
