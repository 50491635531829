body{
    min-height: 100%;
    margin-bottom:0!important;
}


// Config

@import 'config/variables';

// Bootstrap
    $fa-font-path : "~@fortawesome/fontawesome-free/webfonts";
    @import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
    @import "~@fortawesome/fontawesome-free/scss/solid.scss";
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';
    @import '~bootstrap/scss/reboot';
    @import '~bootstrap/scss/grid';
    @import '~bootstrap/scss/images';
    @import '~bootstrap/scss/type';
    @import '~bootstrap/scss/forms';
    @import '~bootstrap/scss/utilities';
    @import '~bootstrap/scss/modal';
    @import '~bootstrap/scss/alert';
    @import '~bootstrap/scss/close';
    @import '~bootstrap/scss/nav';
    @import '~bootstrap/scss/navbar';
    @import '~bootstrap/scss/transitions';
    @import '~bootstrap/scss/carousel';
    @import '~bootstrap/scss/progress';
    @import '~bootstrap/scss/tables';
    @import '~bootstrap/scss/dropdown';
    @import '~bootstrap/scss/buttons';
    @import '~bootstrap/scss/pagination';
    
// Config

    @import 'config/mixins';
    @import 'config/fonts';
    @import 'config/type';

// Utilities

    @import 'utilities/min-heights';
    @import 'utilities/heights';
    @import 'utilities/widths';
    @import 'utilities/max-widths';
    @import 'utilities/backgrounds';
    @import 'utilities/box-shadows';
    @import 'utilities/border-radius';
    @import 'utilities/text-colours';
    @import 'utilities/text-style';
    @import 'utilities/text-aligns';
    @import 'utilities/font-sizes';
    @import 'utilities/image';
    @import 'utilities/loading';
    @import 'utilities/margin';
    @import 'utilities/padding';
    @import 'utilities/position';
    @import 'utilities/z-index';

// Non-generic

    @import 'non-generic/backgrounds';
    @import 'non-generic/buttons';
    @import 'non-generic/header';
    @import 'non-generic/lists';
    @import 'non-generic/overrides';
    @import 'non-generic/language-specific';

        // Components

            @import 'non-generic/components/carousels';
            @import 'non-generic/components/custom-dropdowns';
            @import 'non-generic/components/forms';

        // Pages
            
            @import 'non-generic/pages/dashboard';
            @import 'non-generic/pages/download-centre';
            @import 'non-generic/pages/login';
            @import 'non-generic/pages/user';
            @import 'non-generic/pages/suppliers';
            @import 'non-generic/pages/questionnaire';
            @import 'non-generic/pages/new-products';

        // Components

            @import 'non-generic/components/header-search-form';
            @import 'non-generic/components/product-banner';
            @import 'non-generic/components/product-item';
            @import 'non-generic/components/modals';
            @import 'non-generic/components/tables';
    
// Fontello

    @import '../../frontend/fontello/css/animation';
    @import '../../frontend/fontello/css/fontello';

    
