// French and German

	html:lang(fr),
	html:lang(de){

		// Global
			
			.btn{
				@include media-breakpoint-up(lg){
					font-size: 0.85rem;
				}
			}

		// Menu

			#header {
				.dropdown-menu {
					ul {
						li{
							a{
								font-size: 0.9rem;
							}
						}
					}
				}
			}

		// Dashboard

			.taskboard{
				padding: 40px 60px 42px 60px;
				h2{
					@include media-breakpoint-down(lg){
						font-size: 1.3rem;
					}
				}
			}

			div.list{
				&.hex{
					.child{
						p{
							line-height: 1.2;
							display: block;
							&:before{
								top: 2px;
								transform: none;
							}

							a{
								@include media-breakpoint-down(md){
									font-size: 0.8rem;
								}
							}
						}
						}
				}
			}

			// Training

				.product-banner{
					p{
						word-break: break-all;
					}
				}

			// Marketing Tab

				.product-item{

					h2{
						@include max-width-1399{
							line-height: 1;
						}
					}

					.action-link{
						&.download{
							font-size: 1rem;
							padding-left: 1rem;
							
							@include max-width-1399{
								line-height: 1.5;
								font-size: 0.8rem;
								padding-left: 0.5rem;
								&:before{
									width: 20px;
									height: 20px;
									@include media-breakpoint-down(lg){
										left: -10px;
									}
								}
							}

							@include media-breakpoint-down(lg){
								font-size: 0.8rem;
							}

						}
					}

				}

			// Questionnaire

				#items-carousel {
					.single-carousel-item {
						span{
							left: 50%;
							padding: 0 2rem;
							width: 80%;
							line-height: 1;
							transform: translateX(-50%);
						}
					}
				}
	
	}

// German

	html:lang(de){
		.product-item{
			p{
				word-break: break-word;
			}
		}
	}


// Spanish and Italian

	html:lang(es),
	html:lang(it){
		#items-carousel .single-carousel-item span{
			left: 50%;
			width: 80%;
			transform: translateX(-50%);
			line-height: 1;
			bottom: 30px;
			@include media-breakpoint-down(lg){
				font-size: 0.8rem;
			}
		}
	}

// ES / FR / IT

	html:lang(es),
	html:lang(it),
	html:lang(fr){
		#login-box {

			h1{font-size: 1rem;}
			input[type=submit]{width: 125px;}


		}
	}