.video-decription{
    padding-bottom: 20px;
    p{
        width: 80%;
        line-height: 21px;
        @include media-breakpoint-down(sm){
            width: 100%;
            font-size: 14px;
            line-height: 16px;
        }
    }
}

.text-icon{
    img{
        height: 31px;
        width: 20px;
    }

    font-weight: 900;
}

.video-container {
    position:relative;
    padding-bottom: 47.65%;
    padding-top: 100px;
    overflow: hidden;
    @include media-breakpoint-down(lg){
        padding-bottom:  44.9%;
    }
    @include media-breakpoint-down(sm){
        padding-bottom:  31.25%;
    }

}

.video-player {
    width: 100%;
    height: 100%;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.progress{
    height: 1.5rem;
}

.question-block{
    position: relative;

} 

.overlay-container{
    position: relative;
    z-index: 1000;
    height: 100%;
    width: 100%;
    margin-top: -110px;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255,255,255,0.92);
    z-index: 1000;
    width:100%;
    height:100%;
    @include media-breakpoint-down(sm){
        margin-top: -65px;
        background-color: rgba(255, 255, 255, 1);
    }
}

#questionnaire-container{
    
    h2:first-of-type{
        text-transform: capitalize;
        @include media-breakpoint-down(sm){
            font-size: 1.2rem;
            line-height: 1.4rem;
        }
    }
    
    .product-banner{
        padding: 10px 100px 40px 100px;
        @include media-breakpoint-down(sm){
            padding: 0 20px;
        }
    }

    .progress{
        box-shadow: inset 0 0 3px rgba(0,0,0,.48);
        background-color: $white;
        .progress-bar{
            border-radius: 15px;
            background-image: url('#{$imgDir}/backgrounds/hex-bg.png');
            background-size: contain;
        }
    }

}

.questions-wrapper{
    position: relative;
    margin-top: 60px;

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label{
        position: relative;
        padding-left: 45px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: $old-black;
        text-transform: capitalize;
        margin-bottom: 30px;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 23px;
        height: 23px;
        box-shadow: inset 0 0 3px #b9b0b0;
        border-radius: 100%;
        background: #fff;
        @include media-breakpoint-down(sm){
            width: 15px;
            height: 15px;
        }
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 15px;
        height: 15px;
        background: $blue;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        @include media-breakpoint-down(sm){
            width: 9px;
            height: 9px;
            top: 3px;
            left: 3px;
        }
    }

    [type="radio"]:checked + label span{
        color: $blue;
    }

    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    @include media-breakpoint-down(sm){

        margin-top: 30px;
        
        p{
            font-size: 14px;
            line-height: 16px;
        }

        h4.font-size-20{
            font-size: 16px;
            line-height: 18px;
            br{
                display: none;
            }
        }

        h5{
            font-size: 1rem;
        }

        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label{
            font-size: 14px;
            padding-left: 25px;
            line-height: 15px;
            margin-bottom: 15px;
        }

    }

}

.taskboard{
    overflow: auto;
    
    div.list.hex{
        overflow: auto;
        height: 350px;
        @include media-breakpoint-down(sm){
            margin-bottom: 0;
        }
        div{
            margin-bottom: 1.5rem;
            a{
                transition: 0.5s;
                &:hover{
                    color: $blue;
                }
            }
            @include media-breakpoint-down(sm){
                width: 100%;
                margin-bottom: 0.8rem;
            }
        }
    }

    .list{
        &.hex{
            .list-inner-wrapper{
                column-count: 2;
            }
            div.child{
                p{
                    padding-left: 40px;
                    font-weight: 700;
                    font-size:  18px;
                    line-height: 25px;
                    margin-bottom: 0;
                    position: relative;
                    
                    
                    &:before{
                        content: '';
                        background-image: url('#{$imgDir}/icons/hex-bullet.svg');
                        background-repeat: no-repeat;
                        width: 15px;
                        height: 18px;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);

                        @include media-breakpoint-down(sm) {
                            width: 15px;
                            height: 15px; 
                        }

                    }

                    @include max-width-1399{
                        a{font-size: 16px;}
                    }

                    @include media-breakpoint-down(lg){
                        margin-bottom: 2rem;    
                    }

                    @include media-breakpoint-down(sm){
                        line-height: 18px;
                        padding-left: 20px;
                        a{font-size: 14px;}
                    }

                    a{
                        color: $black;
                        &:hover{text-decoration: none;}
                    }
                }
            }   
        }
    }

}

#items-carousel{

    .inner-wrapper{
        @extend .border-radius-15;
        @extend .box-shadow;
        padding: 60px 60px 80px 60px;
        @include media-breakpoint-down(sm){
            padding: 20px 0;
            h2{
                font-size: 1.3rem;
            }
        }
    }
    
    .carousel-indicators {
        margin-bottom: 2.5rem;
        li {
            background-color: $old-black;
        }
    }

    .single-carousel-item{
        @extend .border-radius-15;
        @extend .box-shadow;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        width: 200px;
        height: 200px;
        @include media-breakpoint-down(lg){
            width: 180px;
            height: 180px;
        }
        span{
            position: absolute;
            bottom: 35px;
            text-align: center;
            left: 0;
            width: 100%;
        }
        img{
            margin-top: -20px;
        }
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            width: 100%;
            height: 100%;
            &:hover{
                text-decoration: none;
            }
        }
        @include media-breakpoint-down(md){
            margin: 10px 0;
        }
    }

}

#furtherReadingWrapper, .new-products-wrapper{

    #furtherReadingCarousel{
        padding-bottom: 50px;
        
        h2{
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
        }

        p{
            font-size: 15px;
            line-height: 20px;
        }

        .carousel-indicators li{
            background-color: $black;
        }
    }

    @include media-breakpoint-down(sm){
        padding-top: 20px;
        
        h2{
            font-size: 1.3rem;
        }

        #furtherReadingCarousel{
            .carousel-item{
                padding: 0;
                
                h2{
                    font-size: 16px;
                    line-height: 18px;
                }

                p{
                    font-size: 14px;
                    line-height: 16px;
                }

                .transbox a{
                    padding: 20px;
                }

            }
        }
    }

}

.transcript-text{
    @extend .text-blue;
    @extend .text-right;
    @extend .mt-4;
    @extend .pb-3;
    @extend .mr-4;
    @extend .mb-5;
    img{
        margin-top: -7px;
    }
    @include media-breakpoint-down(sm){
        margin-bottom: 15px;
    }
}

.btn-answer{
    background-color: $blue;
    outline: none;
    border: solid transparent;
    border-radius: 10px;
    padding: 10px 50px;
    position: relative;
    top: 79%;
    font-size: 18px;
    font-weight: 700;
    @include media-breakpoint-down(sm){
        font-size: 14px;
        padding: 5px 20px;
    }

    &:disabled {
        background-color: $backColor;
    }
}

#questionnaire-attempt-question-answer-options{
    column-count: 2;
    font-size: 18px;
    font-weight: 700;
    @include media-breakpoint-down(lg){
        column-count: 2;
    }
    @include media-breakpoint-down(sm){
        column-count: 1;
    }
    input[type="radio"]{
        margin-bottom: 10px;
        margin-right: 10px;
    }
}

#furtherReadingCarousel,
.new-products-wrapper{

    .carousel-item {
        padding: 0 25px;
    }
 
    .custom-width{
        @include media-breakpoint-up(lg){
            flex: 0 0 49%;
            max-width: 49%;
        }
         .further-reading-item{
             @extend .border-radius-15;
             margin-bottom: 10px;
             height: 230px;
            position: relative;

            .grow {
                transition: all .2s ease-in-out;
            }

            &:hover {
                .grow {
                    transition: all .2s ease-in-out;
                    transform: scale(1.1); 
                }
            }

            .transbox {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
                background: -webkit-linear-gradient(rgba(62, 62, 62, 0.5) 0%, rgba(40,40, 40, 0.5) 100%);
                background: -o-linear-gradient(rgba(62, 62, 62, 0.5) 0%, rgba(40,40, 40, 0.5) 100%);
                background: linear-gradient(rgba(62, 62, 62, 0.5) 0%, rgba(40,40, 40, 0.5) 100%);
                border-radius: 15px;
                color: $white;
            }


        }       
       @include media-breakpoint-down(xs) {
           h2{
                font-size: 18px;
            }
         
            p{
                font-size: 14px;
            }
        }
    }
}

.more-description{
    font-weight: 700;
}

.results-box{
    @extend .box-shadow;
    @extend .border-radius-15;
    @extend .bg-white;
    padding: 80px 30px 60px 30px;
    width: 80%;
    margin: -45px auto 0 auto;
    min-height: 350px;

    .result-text{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 25px;
    }

    a{
        border-bottom: 1px solid $blue; 
        color: $blue;
        &:hover{text-decoration: none;}
    }

    h3{
        font-weight: 700;
    }

    p{
        margin-bottom: 0.5rem;
        font-size: 16px;
        line-height: 21px;
    }

    @include media-breakpoint-down(sm){
        
        width: 100%;
        padding: 20px;
        
        h3{
            font-size: 1.2rem;
        }

        p{
            font-size: 14px;
            line-height: 16px;
        }

        .result-text{
            font-size: 18px;
            margin-top: 25px;
        }
    }

    .return-text{
        margin-top: 30px;
         a{
            font-size: 20px;
            font-weight: 500;
            color: $blue;
        }
    }
}

.read{
    .bg-cover{
        //min-height: 400px;
    }
}
    