h2{
	@include media-breakpoint-up(lg){
		font-size: 2.5rem;
		line-height: 2.5rem;
		font-weight: 700;
	}
    @include media-breakpoint-down(sm){
        font-size: 1.5rem;
    }
}



