.supplier-info,
.user-info{
  label{
    font-size: 15px;
  }
  h4,address{
    font-size: 24px;
  }
}

#buttons-drop{
   @include media-breakpoint-down(md){
      flex-direction: column;
   }
}


.table-responsive-stack tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .table thead th {
   border-radius: 30px 0px 0px 0px;
   padding-bottom: 20px;
   padding-top: 20px;
   font-size: 1.5rem;
   font-weight: 900;
  }

  table  .my-custom-scrollbar {
   position: relative;
   height: 300px;
   overflow: auto;
 }
 .table-wrapper-scroll-y {
   display: block;
 }
  table td {
   border: 3px transparent solid;
   border-right: solid black 1px;
   @include media-breakpoint-down(sm){
      border-right: solid transparent 3px;


   }
  
    
  }
  

  
  
  .table-responsive-stack td,
  .table-responsive-stack th {
     display:block;
  /*      
     flex-grow | flex-shrink | flex-basis   */
     -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  }
  
  .table-responsive-stack .table-responsive-stack-thead {
     font-weight: bold;
  }
  
  @media screen and (max-width: 768px) {
     .table-responsive-stack tr {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        display:block;
        
     }
     /*  IE9 FIX   */
     .table-responsive-stack td {
        float: left\9;
        width:100%;
     }
  }
  
  
  .suppliers{
   .dropdown{
      width: 36%;
      margin: auto;
      font-size: 1.5rem;
      @include media-breakpoint-down(sm){
         width: 60%;
      }
      .btn{
         outline: none;
      }
      button{
         padding: 19px 20px;
         width: 90%;
         color: $black;
         font-weight: 900;
         background-color: white;
         text-align: left;
         outline: none;
         @include media-breakpoint-down(sm){
            width: 100%;
         }
         

      }
      
   }

  }

  .dropdown-toggle::after {
   display: none;
}

.fa-chevron-down{
   color: $blue;
   margin-left: 164px; 
   font-size: 2rem;
   @include media-breakpoint-down(lg){
      font-size: 1.5rem;
      margin-left: 40px;
      padding-left: 30px;

   }
}

.searchBarWrapper  {
   height: 100%;
   margin: auto;
   position: relative;
   width: 100%;

   h1 {
      margin: 2rem;  
    }
    
    .searchbar {
      font-size: 16px;
      width: 100%;
      border: none;
      outline: none;
      border-radius: 10rem;
      box-shadow: 0 1rem 2rem rgba(black, .1);
      padding: 0.6rem 1.3rem;
      // position: relative;
      transition: all .2s;
      transition-delay: .1s;
      margin-bottom: 15px;
      font-weight: 500;
      box-shadow: inset 0 0 3px #484343c2;
      color: $old-black;
      &::-ms-clear {
          display: none;
      }
    }
    
    
    .button {
      font-size: 16px;
      position: absolute;
      top: 4px;
      right: -5px;
      transition: all .2s;
      transition-delay: .1s;
    }
    
    .button:hover {
      cursor: pointer;
    }
    
    .searchbar::placeholder {
      color: $black;
    }
 }
 
 