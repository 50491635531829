body.download-tier-2 {
    .container {
        max-width: 1380px;
    }
}

.w-65 {width: 65% !important;}

.width-100-pc {
    width: 100%;
}

.button-width{
        width: 100%;
        
        @include media-breakpoint-up(lg){
            width: 15%;
            &.width-38{width: 38%;}
            &.width-41{width: 41%;}
            &.width-43{width: 43%;}
            &.width-lg-55{width: 55%;}
        }
    
        @include media-breakpoint-up(xl){
            &.width-xl-41{width: 41%;}
        }

    }

.w-195{
    min-width: 195px;

}

.w-520 {
    width: 520px;
}

.container {
    &.notfound, .search{
        max-width: 1423px;
        min-height: 71vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include media-breakpoint-down(lg){

            min-height: auto;

        }
    }
}
