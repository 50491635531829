.product-banner{
	background-size: cover;
	background-position: center center;
	min-height: 400px;
	padding: 2px 30px;
	position: relative;
	@extend .box-shadow;
	@extend .border-radius-15;
	
	h2,p{color: $white;}
	
	h2{
		text-transform: capitalize;
	}

	p{
		font-weight: 500;
		line-height: 21px;
		@include media-breakpoint-down(sm){
			font-size: 14px;
			line-height: 16px;
		}
	}

}

#questionnaire-container{
	.product-banner{
		.product-banner-item{
			background-size: contain;
			background-repeat: no-repeat;
			position: absolute;
			@include media-breakpoint-down(md){
				display: none;
			}
		}

		&.battery-holders{
			.product-banner-item{
				right: 1%;
				bottom: 13%;
				width: 35%;
				padding-bottom: 18%;
			}
		}

		&.circular-automation-connectors{
			.product-banner-item{
				right: 4%;
				bottom: 10%;
				width: 35%;
				padding-bottom: 22%;
			}
		}

		&.circular-data-connectors{
			padding: 1% 100px 5% 100px;
			.product-banner-item{
				right: 6%;
				bottom: 11%;
				width: 27%;
				padding-bottom: 18%;
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

        &.circular-fiber-connectors{
            padding: 2% 100px 5% 100px;
            .product-banner-item{
                top:0;
                right: 7%;
                width: 100%;
                min-height: 480px;
                height: 100%;
                background-position: right 140px;
                background-size: 75%;
                filter: brightness(0.8);
            }
            @include media-breakpoint-down(sm){
                padding: 0 20px;
            }
        }

		&.circular-power-connectors{
			padding: 2% 100px 8% 100px;
			.product-banner-item{
				right: 6%;
				bottom: 1%;
				width: 30%;
				padding-bottom: 28%;
			}
		}

		&.emi-mains-filters{
			padding: 1% 100px 5% 100px;
			.product-banner-item{
				right: 5%;
				bottom: 12%;
				width: 30%;
				padding-bottom: 15%;
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

		&.enclosures{
			padding: 1% 100px 9% 100px;
			.product-banner-item{
				right: 5%;
				bottom: 12%;
				width: 32%;
				padding-bottom: 16%;
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

		&.fuse-holders{
			padding: 1% 100px 9% 100px;
			.product-banner-item{
				right: 0%;
				bottom: 12%;
				width: 32%;
				padding-bottom: 25%;
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

		&.iec-connectors{
			padding: 1% 100px 9% 100px;
			.product-banner-item{
				right: 0%;
				bottom: 12%;
				width: 42%;
				padding-bottom: 16%;
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

		&.iec-power-distribution-units{
			padding: 1% 100px 9% 100px;
			.product-banner-item{
				right: 8%;
				bottom: 0;
				width: 40%;
				padding-bottom: 35%;
				z-index: 0;
				background-position: bottom right;
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

		&.indicators{
			padding: 1% 100px 9% 100px;
			.product-banner-item{
				right: -2%;
				bottom: -5%;
				width: 58%;
				padding-bottom: 25%;
				z-index: 0;
				background-position: bottom right;
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

		&.power-entry-modules{
			padding: 1% 100px 9% 100px;
			.product-banner-item{
				right: -1%;
				bottom: 0%;
				width: 45%;
				padding-bottom: 20%;
				z-index: 0;
				background-position: bottom right;
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

		&.sensors{
			padding: 1% 100px 9% 100px;
			.product-banner-item{
				right: 0;
				bottom: 7%;
				width: 45%;
				padding-bottom: 20%;
				z-index: 0;
				background-position: bottom right;
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

		&.switches{
			padding: 1% 100px 6% 100px;
			.product-banner-item{
				right: 0;
				bottom: 7%;
				width: 25%;
				padding-bottom: 20%;
				z-index: 0;
				background-position: bottom right;
			}
			.text-column{
				@include media-breakpoint-up(lg){
					flex: 0 0 76.666667%;
					max-width: 76.666667%;
				}
			}
			@include media-breakpoint-down(sm){
				padding: 0 20px;
			}
		}

	}
}