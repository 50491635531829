// Font Weight

    .bold{font-weight: 900;}

// Spacing

    $spacing: 1rem;

    .font-18{font-size: 18px;}
    .font-21{font-size: 21px;}

    .no-underline-on-hover:hover {
    	text-decoration: none;
    }

    @include media-breakpoint-up (sm) {
        .text-sm-nowrap {
            white-space: nowrap !important;
        }
    }

    .break-word{word-wrap: break-word;}

// Line Height

    .line-height-1{line-height: 1;}
