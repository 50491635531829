// Paths
    
    $fontDir: '/fonts';
    $imgDir: '/img';


// Colours

    $backColor: #ebebeb;
    $black: #000;
    $blue: 	#0055b9;
    $green: #48AA07;
    $light-grey: #9E9E9E;
    $lighter-grey: #E4E4E4;
    $red:	#CC0000;
    $white: #fff;
    $old-black: #0d0d0d;
    $cobalt-blue: #0055b9; 
    
// Bootsrap Overides

    $font-family-base: 'HelveticaNeue';
    $modal-backdrop-bg: white;
    $modal-backdrop-opacity:  0.9;  
    $link-hover-color: $blue;

//text shadow	

    $textShadow: rgba(black, 0.35) 1px 1px 1px;

// Progress Bar

    $step: 5;
    $loops: round(100 / $step);
    $increment: 360 / $loops;
    $half: round($loops / 2);
