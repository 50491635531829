.table.custom-table{
    thead {
        background-color: transparent;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        
        tr{
            th{
                border-radius: 0;
                border: none;
                text-align: center;
                color: $old-black;
                font-size: 18px;
                padding-top: 15px;
                padding-bottom: 15px;
                background-color: $white;
                position: relative;
                &:first-of-type{border-top-left-radius: 10px;}
                &:last-of-type{border-top-right-radius: 10px;}
                &.sort.column:before{
                    top: 20px;
                    right: 2.85em;
                }

                &.sort.column:after{
                    top: 20px;
                    right: 1.85em;
                }
                &.sorting_desc{
                    .table-heading{
                        transform: rotate(180deg);
                        margin-right: 0;
                        margin-left: 7px;
                    }
                }
            }
        }

        .table-heading{
            background-image: url('/img/icons/filter-arrows.svg');
            background-size: contain;
            width: 20px;
            height: 12px;
            display: inline-block;
            background-repeat: no-repeat;
            margin-left: 8px;
            margin-bottom: -1px;
        }
    }
    tr{
        td:last-of-type{border: none;}
        &:first-of-type{
            td{
                
            }
        }
        &:last-of-type{
            td{
                &:first-of-type{border-bottom-left-radius: 10px;}
                &:last-of-type{border-bottom-right-radius: 10px;}
            }
        }
        &:hover{
            td{
                color: $blue;
                a{
                    color: $blue;
                    text-decoration: underline;
                }
            }
        }
        &.custom-table-selection{
            td:first-of-type{
                @include media-breakpoint-down(lg){
                    padding-left: 5%;
                    span{
                        left: 15px;
                    }
                }
            }
            &.selected{
                color: $blue;
                a{color: $blue}
                
            }
            &.deleted-data-row {
                color: $danger;
            }
        }
    }
    td{
        border: none;
        text-align: center;
        border-right: 2px solid black;
        font-size: 14px;
        font-weight: 500;
        vertical-align: middle;
        padding: 8px 0;
        position: relative;

        .custom-table-checkboxes{
            left: 35px;
            top: 10px;
            position: absolute;
        }
        a{
            font-size: 14px;
            color: $old-black;
            font-weight: 700;
            transition: 0.5s;
            &:hover{
                color: $blue;
            }
        }
    }
}

.custom-table.table-striped tbody tr:nth-of-type(odd){
    background-color: $backColor;
}

.custom-checkbox{
    opacity: 0;
    position: absolute;   
}

.custom-checkbox, 
.custom-checkbox-label{
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.custom-checkbox-label{
    position: relative;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 50%;
    box-shadow: inset 0 0 3px #5d5959;
}

.custom-checkbox + .custom-checkbox-label:before{
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 9px;
    height: 9px;
    padding: 2px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-table-selection {

    .custom-table-checkboxes{
        
        .custom-checkbox {
            &:checked {
                + .custom-checkbox-label:before{
                    content: "";
                    background: $blue;
                    color: #fff;
                }
            }
        }
        
        &.custom-table-restore-checkboxes{
            .custom-checkbox:checked + .custom-checkbox-label:before {
                background: $red;
            }
        }        
    }

    .deleted-data-row{
        color: $red!important;
    }
}

table.custom-table.dataTable thead .sorting:before, 
table.custom-table.dataTable thead .sorting:after, 
table.custom-table.dataTable thead .sorting_asc:before, 
table.custom-table.dataTable thead .sorting_asc:after, 
table.custom-table.dataTable thead .sorting_desc:before, 
table.custom-table.dataTable thead .sorting_desc:after, 
table.custom-table.dataTable thead .sorting_asc_disabled:before, 
table.custom-table.dataTable thead .sorting_asc_disabled:after{
    content: '';
}

