/*
    Font sizes
*/

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}


.font-size-20 {
    font-size: 20px;
}

.font-size-30 {
    font-size: 30px;
}

@include media-breakpoint-down(md) {
    .font-size-13-md-down {
        font-size: 13px;
    }
}

@include media-breakpoint-down(xs) {
    .font-size-15-xs-down {
        font-size: 15px;
    }
}
