.img-icon{
    max-width: 100%;
}

.notfound-icon, .noresults-icon{

    width: 411px;
    @include media-breakpoint-down(sm){

        width: 300px;

    }
   

}
