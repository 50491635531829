.new-products-wrapper{
	.custom-width{
        .further-reading-item{
			margin-bottom: 10px;
			height: 230px;
			position: relative;

			.transbox {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
                background: none;
                border-radius: 15px;
                color: $white;
				h2{
					font-size: 22px;
					font-weight: 700;
					line-height: 24px;
				}
				p{
					width: 94%;
				}
            }

			.grow{
				transition: all .2s ease-in-out;
			}

			&:hover {
	            &.grow {
	                transition: all .2s ease-in-out;
	                transform: scale(1.1); 
	            }
	        }

        }
	}

	.new-product-box{
		position: relative;
		height: 230px;
		background-size: cover;
		background-position: center center;
		border-radius: 15px;
		padding-bottom: 10px;
		&.grow{
			transition: all .2s ease-in-out;
		}

		&:hover {
            &.grow {
                transition: all .2s ease-in-out;
                transform: scale(1.1); 
            }
        }

	}

	.new-product-box-text{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		a{
			color: $white;
			display: block;
			padding: 40px 75px 40px 40px;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			&:hover{text-decoration: none;}

		}

		span{
			display: block;
		}

		.title{
			font-size: 1.4rem;
			line-height: 1.5rem;
			font-weight: bold;
			margin-bottom: 0.8rem;
			text-transform: capitalize;
		}

		.text{
			font-size: 0.95rem;
			line-height: 1.4rem;
			width: 90%;
		}
	}
}

iframe#product-landing-page {
	width: 100%;
	height: 100vh;
	border: 0;
	padding-bottom: 150px;
}

